import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { CContainer, CSpinner } from "@coreui/react-pro";

// routes config
import routesFastFood from "../routesFastFood";

const AppContentFastFood = () => {
  return (
    <CContainer lg className="sales">
      <Suspense fallback={<CSpinner color="primary" />}>
        <Switch>
          {routesFastFood.map((route, idx) => {
            return (
              route.component && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={(props) => (
                    <>
                      <route.component {...props} />
                    </>
                  )}
                />
              )
            );
          })}
          {/* <Redirect from="/" to="/dashboard" /> */}
        </Switch>
      </Suspense>
    </CContainer>
  );
};

export default React.memo(AppContentFastFood);
