import React from "react";

const Dashboard = React.lazy(() =>
  import("./views/kalasanPages/dashboard/Dashboard")
);
const Outlet = React.lazy(() =>
  import("./views/kalasanPages/master/outlet/Outlet")
);
const Jabatan = React.lazy(() =>
  import("./views/kalasanPages/master/jabatan/Jabatan")
);
const Satuan = React.lazy(() =>
  import("./views/kalasanPages/master/satuan/Satuan")
);
const Rack = React.lazy(() => import("./views/kalasanPages/master/rak/Rack"));
const Printer = React.lazy(() =>
  import("./views/kalasanPages/master/printer/Printer")
);
const ManajemenRole = React.lazy(() =>
  import("./views/kalasanPages/pengaturan/manajemenRole/ManajemenRole")
);
const ManajemenMenu = React.lazy(() =>
  import("./views/kalasanPages/pengaturan/manajemenMenu/ManajemenMenu")
);
const ManajemenPengguna = React.lazy(() =>
  import("./views/kalasanPages/pengaturan/manajemenPengguna/ManajemenPengguna")
);
const KonfigurasiUmum = React.lazy(() =>
  import("./views/kalasanPages/pengaturan/konfigurasiUmum/KonfigurasiUmum")
);
const ManajemenHakAkses = React.lazy(() =>
  import("./views/kalasanPages/pengaturan/manajemenHakAkses/ManajemenHakAkses")
);

const DataPerusahaan = React.lazy(() =>
  import("./views/kalasanPages/pengaturan/dataPerusahaan/DataPerusahaan")
);
const AuditTrail = React.lazy(() =>
  import("./views/kalasanPages/histori/auditTrail/AuditTrail")
);
const Notifikasi = React.lazy(() =>
  import("./views/kalasanPages/histori/notifikasi/Notifikasi")
);
const Gudang = React.lazy(() =>
  import("./views/kalasanPages/master/gudang/Gudang")
);
const Supplier = React.lazy(() =>
  import("./views/kalasanPages/master/supplier/Supplier")
);
const Pegawai = React.lazy(() =>
  import("./views/kalasanPages/master/pegawai/Pegawai")
);
const Customer = React.lazy(() =>
  import("./views/kalasanPages/master/customer/Customer")
);
const SOP = React.lazy(() => import("./views/kalasanPages/master/SOP/SOP"));
const RuanganMeja = React.lazy(() =>
  import("./views/kalasanPages/master/ruanganMeja/RuanganMeja")
);
const Produk = React.lazy(() =>
  import("./views/kalasanPages/master/produk/Produk")
);
const KodeAkun = React.lazy(() =>
  import("./views/kalasanPages/master/kodeAkun/KodeAkun")
);
const Divisi = React.lazy(() =>
  import("./views/kalasanPages/master/divisi/Divisi")
);
const PermintaanOwner = React.lazy(() =>
  import("./views/kalasanPages/inventory/permintaanOwner/PermintaanOwner")
);
const PinjamanKaryawan = React.lazy(() =>
  import("./views/kalasanPages/personalia/pinjaman/PinjamanKaryawan")
);
const Chat = React.lazy(() => import("./views/kalasanPages/histori/chat/Chat"));
const Absensi = React.lazy(() =>
  import("./views/kalasanPages/personalia/absensi/Absensi")
);
const LogAbsen = React.lazy(() =>
  import("./views/kalasanPages/personalia/lihatAbsen/LihatAbsensi")
);
const FormulaPayroll = React.lazy(() =>
  import("./views/kalasanPages/personalia/formulaPayroll/FormulaPayroll")
);
const Payroll = React.lazy(() =>
  import("./views/kalasanPages/personalia/payroll/Payroll")
);
const PermintaanPusat = React.lazy(() =>
  import("./views/kalasanPages/permintaan/permintaanPusat/PermintaanPusat")
);
const PermintaanDivisi = React.lazy(() =>
  import("./views/kalasanPages/permintaan/permintaanDivisi/PermintaanDivisi")
);
const RekapPermintaanDivisi = React.lazy(() =>
  import(
    "./views/kalasanPages/permintaan/rekapPermintaanDivisi/RekapPermintaanDivisi"
  )
);
const RekapPermintaanPusat = React.lazy(() =>
  import(
    "./views/kalasanPages/permintaan/rekapPermintaanPusat/RekapPermintaanPusat"
  )
);
const RekapPermintaan = React.lazy(() =>
  import("./views/kalasanPages/pembelian/rekapPermintaan/RekapPermintaan")
);
const RekapPermintaanBarangPajak = React.lazy(() =>
  import(
    "./views/kalasanPages/pembelian/rekapPermintaanBarangPajak/RekapPermintaanBarangPajak"
  )
);
const DaftarPembelian = React.lazy(() =>
  import("./views/kalasanPages/pembelian/daftarPembelian/DaftarPembelian")
);
const DaftarPO = React.lazy(() =>
  import("./views/kalasanPages/pembelian/daftarPO/DaftarPO")
);
const Container = React.lazy(() =>
  import("./views/kalasanPages/inventory/container/Container")
);
const RekapPermintaanGudang = React.lazy(() =>
  import(
    "./views/kalasanPages/inventory/rekapPermintaanGudang/RekapPermintaanGudang"
  )
);
const LihatStok = React.lazy(() =>
  import("./views/kalasanPages/inventory/lihatStok/LihatStok")
);
const StokExpired = React.lazy(() =>
  import("./views/kalasanPages/inventory/stokExpired/StokExpired")
);
const Stokin = React.lazy(() =>
  import("./views/kalasanPages/inventory/stokMasuk/StockIn")
);
const StockOut = React.lazy(() =>
  import("./views/kalasanPages/inventory/stokKeluar/StockOut")
);
const PenyesuaianStok = React.lazy(() =>
  import("./views/kalasanPages/inventory/penyesuaianStok/PenyesuaianStok")
);
const MutasiBarang = React.lazy(() =>
  import("./views/kalasanPages/inventory/mutasiBarang/MutasiBarang")
);
const PermintaanBarang = React.lazy(() =>
  import("./views/kalasanPages/inventory/permintaanBarang/PermintaanBarang")
);
const PengirimanBarang = React.lazy(() =>
  import("./views/kalasanPages/inventory/pengirimanBarang/PengirimanBarang")
);
const PenerimaanBarang = React.lazy(() =>
  import("./views/kalasanPages/inventory/penerimaanBarang/PenerimaanBarang")
);
const Asset = React.lazy(() =>
  import("./views/kalasanPages/inventory/asset/Asset")
);
const Kitchen = React.lazy(() =>
  import("./views/kalasanPages/penjualan/kitchen/Kitchen")
);
const DapurTengah = React.lazy(() =>
  import("./views/kalasanPages/penjualan/dapurTengah/DapurTengah")
);
// const Waitress = React.lazy(() =>
//   import("./views/kalasanPages/penjualan/waitress/Waitress")
// );
// const WaitressOnGoing = React.lazy(() =>
//   import("./views/kalasanPages/penjualan/waitress/OnGoing")
// );
// const WaitressStatus = React.lazy(() =>
//   import("./views/kalasanPages/penjualan/waitress/Status")
// );
const DaftarMenu = React.lazy(() =>
  import("./views/kalasanPages/penjualan/daftarmenu/DaftarMenu")
);
const Diskon = React.lazy(() =>
  import("./views/kalasanPages/penjualan/diskon/Diskon")
);
const KategoriMenu = React.lazy(() =>
  import("./views/kalasanPages/penjualan/kategorimenu/KategoriMenu")
);
// const Kasir = React.lazy(() =>
//   import("./views/kalasanPages/penjualan/kasir/Kasir")
// );
// const Kasir = React.lazy(() =>
//   import("./views/kalasanPages/penjualan/kasir/KasirRevamp")
// );
// const Kasir = React.lazy(() =>
//   import("./views/kalasanPages/penjualan/kasir/KasirRevamp3")
// );
const Reservasi = React.lazy(() =>
  import("./views/kalasanPages/penjualan/reservasi/Reservasi")
);

const ReservasiPackage = React.lazy(() =>
  import("./views/kalasanPages/penjualan/reservasiPackage/ReservasiPackage")
);
const Package = React.lazy(() =>
  import("./views/kalasanPages/penjualan/package/Package")
);
const PaymentLoan = React.lazy(() =>
  import("./views/kalasanPages/personalia/riwayatPinjaman/RiwayatPinjaman")
);
const ProsesPermintaan = React.lazy(() =>
  import("./views/kalasanPages/inventory/prosesPermintaan/ProsesPermintaan")
);
const Task = React.lazy(() =>
  import("./views/kalasanPages/personalia/task/Task")
);
const SalesReport = React.lazy(() =>
  import("./views/kalasanPages/penjualan/report/LaporanPenjualan")
);
const OperationalReport = React.lazy(() =>
  import("./views/kalasanPages/penjualan/report/LaporanOperasional")
);
const CancellationReport = React.lazy(() =>
  import("./views/kalasanPages/penjualan/report/LaporanPembatalan")
);
const Panjar = React.lazy(() =>
  import("./views/kalasanPages/penjualan/report/Panjar")
);
const ReportRefund = React.lazy(() =>
  import("./views/kalasanPages/penjualan/report/LaporanRefund")
);
const Feedback = React.lazy(() =>
  import("./views/kalasanPages/personalia/feedback/EmployeeFeedback")
);
const ProdukJasa = React.lazy(() =>
  import("./views/kalasanPages/master/produkJasa/ProdukJasa")
);
const MejaAktif = React.lazy(() =>
  import("./views/kalasanPages/penjualan/report/MejaAktif")
);
const PenjualanProdukJasa = React.lazy(() =>
  import(
    "./views/kalasanPages/penjualan/penjualanProdukJasa/PenjualanProdukJasa"
  )
);
const KonversiReservasiPaket = React.lazy(() =>
  import(
    "./views/kalasanPages/penjualan/konversiReservasiPaket/KonversiReservasiPaket"
  )
);
const DetailLaporanPenjualan = React.lazy(() =>
  import(
    "./views/kalasanPages/penjualan/report/reportComponents/LaporanPenjualanKredit"
  )
);
const RekapPenjualan = React.lazy(() =>
  import("./views/kalasanPages/penjualan/report/RekapPenjualan")
);
const RekapPenjualanNoPrice = React.lazy(() =>
  import("./views/kalasanPages/penjualan/report/RekapPenjualanNoPrice")
);
const RekapFastFood = React.lazy(() =>
  import("./views/kalasanPages/penjualan/report/RekapFastFood")
);
const DaftarSPK = React.lazy(() =>
  import("./views/kalasanPages/produksi/SPK/DaftarSPK")
);
const SettingTimbangan = React.lazy(() =>
  import("./views/kalasanPages/pengaturan/timbangan/SettingTimbangan")
);
const MutasiGudang = React.lazy(() =>
  import("./views/kalasanPages/inventory/mutasiGudang/MutasiGudang")
);

const routes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/dashboard", name: "Dashboard", component: Dashboard },
  {
    path: "/master/position",
    name: "Jabatan",
    component: Jabatan,
  },
  {
    path: "/master/unit",
    name: "Satuan",
    component: Satuan,
  },
  {
    path: "/master/rack",
    name: "Keranjang",
    component: Rack,
  },
  {
    path: "/master/warehouse",
    name: "Gudang",
    component: Gudang,
  },
  {
    path: "/master/supplier",
    name: "Supplier",
    component: Supplier,
  },
  {
    path: "/master/employee",
    name: "Pegawai",
    component: Pegawai,
  },
  {
    path: "/master/product",
    name: "Produk",
    component: Produk,
  },
  {
    path: "/pengaturan/room",
    name: "Ruangan Meja",
    component: RuanganMeja,
  },
  {
    path: "/master/customer",
    name: "Customer",
    component: Customer,
  },
  {
    path: "/master/sop",
    name: "SOP",
    component: SOP,
  },
  {
    path: "/master/role",
    name: "Manajemen Role",
    component: ManajemenRole,
  },
  {
    path: "/master/product-service",
    name: "Produk & Jasa",
    component: ProdukJasa,
  },
  {
    path: "/pengaturan/printer",
    name: "Printer",
    component: Printer,
  },
  {
    path: "/pengaturan/timbangan",
    name: "Timbangan",
    component: SettingTimbangan,
  },
  {
    path: "/pengaturan/manajemen-menu",
    name: "Manajemen Menu",
    component: ManajemenMenu,
  },
  {
    path: "/pengaturan/account",
    name: "Manajemen Pengguna",
    component: ManajemenPengguna,
  },
  {
    path: "/pengaturan/company",
    name: "Data Perusahaan",
    component: DataPerusahaan,
  },
  {
    path: "/pengaturan/manajemen-hak-akses",
    name: "Manajemen Hak Akses",
    component: ManajemenHakAkses,
  },
  {
    path: "/pengaturan/konfigurasi-umum",
    name: "Konfigurasi Umum",
    component: KonfigurasiUmum,
  },
  {
    path: "/history/audit-trail",
    name: "Audit Trail",
    component: AuditTrail,
  },
  {
    path: "/history/notifikasi",
    name: "Notifikasi",
    component: Notifikasi,
  },
  {
    path: "/master/outlet",
    name: "Outlet",
    component: Outlet,
  },
  {
    path: "/master/coa",
    name: "Kode Akun",
    component: KodeAkun,
  },
  {
    path: "/master/division",
    name: "Divisi",
    component: Divisi,
  },
  {
    path: "/permintaan/pusat",
    name: "Permintaan Pusat",
    component: PermintaanPusat,
  },
  {
    path: "/permintaan/divisi",
    name: "Permintaan Divisi",
    component: PermintaanDivisi,
  },
  {
    path: "/permintaan/rekap-divisi",
    name: "Rekap Permintaan Divisi",
    component: RekapPermintaanDivisi,
  },
  {
    path: "/permintaan/rekap-pusat",
    name: "Rekap Permintaan Pusat",
    component: RekapPermintaanPusat,
  },
  {
    path: "/hr/loan",
    name: "Pinjaman Karyawan",
    component: PinjamanKaryawan,
  },
  {
    path: "/hr/task",
    name: "Task Management",
    component: Task,
  },
  {
    path: "/history/chat",
    name: "Penerimaan Pembelian",
    component: Chat,
  },
  {
    path: "/hr/attendance",
    name: "Absensi",
    component: Absensi,
  },
  {
    path: "/hr/report",
    name: "Lihat Absensi",
    component: LogAbsen,
  },
  {
    path: "/hr/paymentLoan",
    name: "Absensi",
    component: PaymentLoan,
  },
  {
    path: "/hr/payroll_formula",
    name: "Formula Payroll",
    component: FormulaPayroll,
  },
  {
    path: "/hr/payroll",
    name: "Payroll",
    component: Payroll,
  },
  {
    path: "/purchase/grouping",
    name: "Rekap Permintaan Pembelian",
    component: RekapPermintaan,
  },
  {
    path: "/purchase/grouping-tax",
    name: "Rekap Permintaan B. Pajak",
    component: RekapPermintaanBarangPajak,
  },
  {
    path: "/purchase/list",
    name: "Daftar Pembelian",
    component: DaftarPembelian,
  },
  {
    path: "/purchase/order",
    name: "Daftar PO",
    component: DaftarPO,
  },
  {
    path: "/production/spk",
    name: "Daftar SPK",
    component: DaftarSPK,
  },
  {
    path: "/inventory/owner",
    name: "Permintaan Owner",
    component: PermintaanOwner,
  },
  {
    path: "/inventory/asset",
    name: "Aset & Inventaris",
    component: Asset,
  },
  {
    path: "/inventory/container",
    name: "Container",
    component: Container,
  },
  {
    path: "/inventory/stok",
    name: "Stok",
    component: LihatStok,
  },
  {
    path: "/inventory/rekap-permintaan",
    name: "Rekap Permintaan Gudang",
    component: RekapPermintaanGudang,
  },
  {
    path: "/inventory/stokin",
    name: "Stok Masuk",
    component: Stokin,
  },
  {
    path: "/inventory/stokout",
    name: "Stok Keluar",
    component: StockOut,
  },
  {
    path: "/inventory/stokexpired",
    name: "Stok Expired",
    component: StokExpired,
  },
  {
    path: "/inventory/adjustment",
    name: "Penyesuaian Stok",
    component: PenyesuaianStok,
  },
  {
    path: "/inventory/mutasi-gudang",
    name: "Mutasi Gudang",
    component: MutasiGudang,
  },
  {
    path: "/inventory/request",
    name: "Permintaan Barang",
    component: PermintaanBarang,
  },
  {
    path: "/inventory/delivery",
    name: "Pengiriman Barang",
    component: PengirimanBarang,
  },
  {
    path: "/inventory/receiptorder",
    name: "Penerimaan Barang",
    component: PenerimaanBarang,
  },
  {
    path: "/sales/product-service",
    name: "Penjualan Produk & Jasa",
    component: PenjualanProdukJasa,
  },
  // {
  //   path: "/sales/order",
  //   exact: true,
  //   name: "Waitress",
  //   component: Waitress,
  // },
  // {
  //   path: "/sales/order/on-going",
  //   exact: true,
  //   name: "Waitress On Going",
  //   component: WaitressOnGoing,
  // },
  // {
  //   path: "/sales/order/status",
  //   exact: true,
  //   name: "Waitress Status",
  //   component: WaitressStatus,
  // },
  {
    path: "/sales/discount",
    name: "Discount",
    component: Diskon,
  },
  {
    path: "/sales/order-menu",
    name: "Daftar Menu",
    component: DaftarMenu,
  },
  {
    path: "/sales/categories-menu",
    name: "Kategori Menu",
    component: KategoriMenu,
  },
  {
    path: "/sales/list",
    name: "Waitress",
    component: Kitchen,
  },
  {
    path: "/sales/mid-kitchen",
    name: "Dapur Tengah",
    component: DapurTengah,
  },
  // {
  //   path: "/sales/cashier",
  //   name: "Kasir",
  //   component: Kasir,
  // },
  {
    path: "/sales/reservasi",
    name: "Reservasi",
    component: Reservasi,
  },
  {
    path: "/sales/reservasi-paket",
    name: "Reservasi Package",
    component: ReservasiPackage,
  },
  {
    path: "/sales/konversi-reservasi-paket",
    name: "Konversi Reservasi Paket",
    component: KonversiReservasiPaket,
  },
  {
    path: "/sales/package",
    name: "Package",
    component: Package,
  },
  {
    path: "/inventory/process",
    name: "Proses Permintaan",
    component: ProsesPermintaan,
  },
  {
    path: "/report/sales",
    name: "Laporan Penjualan",
    component: SalesReport,
  },
  {
    path: "/report/operational",
    name: "Laporan Operasional",
    component: OperationalReport,
  },
  {
    path: "/report/cancel-menu",
    name: "Laporan Pembatalan Menu",
    component: CancellationReport,
  },
  {
    path: "/report/panjar",
    name: "Panjar",
    component: Panjar,
  },
  {
    path: "/report/refund",
    name: "Laporan Refund",
    component: ReportRefund,
  },
  {
    path: "/report/meja-aktif",
    name: "Meja Aktif",
    component: MejaAktif,
  },
  {
    path: "/hr/employeeFeedback",
    name: "Kritik dan Saran",
    component: Feedback,
  },
  {
    path: "/report/detail-report-kas",
    name: "Detail Laporan Penjualan",
    component: DetailLaporanPenjualan,
  },
  {
    path: "/report/rekap-penjualan",
    name: "Rekap Penjualan",
    component: RekapPenjualan,
  },
  {
    path: "/report/rekap-penjualan-no-price",
    name: "Rekap Penjualan No Price",
    component: RekapPenjualanNoPrice,
  },
  {
    path: "/report/rekap-fastfood",
    name: "Rekap Fast Food",
    component: RekapFastFood,
  },
];

export default routes;
