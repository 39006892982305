import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CModalHeader } from "@coreui/react-pro";
import { cilAccountLogout } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import Swal from "sweetalert";
import { useHistory } from "react-router";
import { logout, changeOutlet } from "src/configs/redux/action/userAction";
import Select from "react-select";
import API from "src/utils/API";
import { useQuery } from "react-query";
import ResponseError from "src/views/kalasanPages/penjualan/waitress/waitressComponents/responseError";
import { encode } from "base-64";

const AppHeaderOutlet = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const employeeId = useSelector((state) => state.user.profile.employee_id);
  const profile = useSelector((state) => state.user.profile.name);
  const role = useSelector((state) => state.user.profile.role);
  const token = useSelector((state) => state.user.profile.token);
  const nip = useSelector((state) => state.user.profile.nip);
  const outlet = useSelector((state) => state.user.profile.outlet);
  const outletId = useSelector((state) => state.user.profile.outlet_id);

  const [outletOption, setOutletOption] = useState([]);
  const [outletSelected, setOutletSelected] = useState({
    value: outletId,
    label: outlet,
  });

  const { isLoading: isLoadingGetOutlet } = useQuery(
    ["outlet"],
    () =>
      API.getAllOutlet({
        token,
      }),
    {
      onSuccess: (res) => {
        const result = res.data.map((item) => ({
          value: item.outlet_id,
          label: item.name,
        }));

        setOutletOption(result);
      },
      onError: (err) => {
        ResponseError(err, dispatch, history);
      },
    }
  );

  const handleChangeOutlet = (data) => {
    if (data.value !== outletSelected.value) {
      dispatch(changeOutlet(encode(nip), data.value));
      setOutletSelected(data);
    }
  };

  return (
    <>
      <Select
        id="outlet"
        name="outlet"
        options={outletOption}
        value={outletSelected}
        onChange={handleChangeOutlet}
        styles={{
          container: (provided) => ({
            ...provided,
            minWidth: "200px",
          }),
        }}
      />
    </>
  );
};

export default AppHeaderOutlet;
