/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  CCol,
  CRow,
  CSpinner,
  CFormLabel,
  CFormInput,
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
} from "@coreui/react-pro";
import { useSelector } from "react-redux";
import Swal from "sweetalert";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { encode } from "base-64";
import HistoriAPI from "src/views/kalasanPages/histori/APIHistori";
import { useMutation, useQuery } from "react-query";
import ResponseError from "src/views/kalasanPages/penjualan/waitress/waitressComponents/responseError";
import ListSPV from "./ListSPV";

const DetailNotifikasi = ({ id, visible, closeModal, refetch }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector((state) => state.user.profile.token);
  const employeeId = useSelector((state) => state.user.profile.employee_id);

  const [dataDetail, setDataDetail] = useState([]);
  const [visibleListSpv, setVisibleListSpv] = useState(false);

  const { isLoading: isLoadingGetDetailNotif, refetch: refetchDetailNotif } =
    useQuery(
      ["get-detail-notif", id],
      () =>
        HistoriAPI.getDetailNotif({
          token,
          notifId: id,
          employee_id: employeeId,
        }),
      {
        enabled: !!id,
        onSuccess: (res) => {
          if (res.data) {
            setDataDetail(res.data);
          }
        },
        onError: (err) => {
          ResponseError(err, dispatch, history);
        },
      }
    );

  const { isLoading: isLoadingReadNotif, mutate: updateisChecked } =
    useMutation(HistoriAPI.checkNotif, {
      onSuccess: () => {
        Swal("Reservasi berhasil di cek", "", "success");
        closeModal();
        refetch();
      },
      onError: (err) => {
        ResponseError(err, dispatch, history);
      },
    });

  const handleCheckNotif = (notifDetailId) => {
    Swal("Yakin sudah cek?", {
      dangerMode: true,
      cancel: true,
      buttons: true,
      icon: "info",
    }).then((result) => {
      if (result) {
        updateisChecked({
          token,
          notifId: encode(notifDetailId),
        });
      }
    });
  };

  const handleAddPax = (notifDetailId) => {
    Swal("Apakah ingin melakukan proses penambahan pax ke dapur?", {
      icon: "info",
      buttons: {
        cancel: {
          text: "Cancel",
          value: "cancel",
          visible: true,
          className: "swal-button--cancel",
        },
        reject: {
          text: "Reject",
          value: "reject",
          className: "swal-button--danger",
        },
        process: {
          text: "Proses",
          value: "process",
          className: "swal-button--confirm",
        },
      },
    }).then((value) => {
      if (value === "process") {
        updateisChecked({
          token,
          notifId: encode(notifDetailId),
          action: "PROSES",
        });
      } else if (value === "reject") {
        updateisChecked({
          token,
          notifId: encode(notifDetailId),
          action: "REJECT",
        });
      }
      // Kalau "cancel", tidak melakukan apa-apa
    });
  };

  const handlePilihSpv = () => {
    setVisibleListSpv(true);
  };

  const handleClosePilihSpv = () => {
    setVisibleListSpv(false);
  };

  return (
    <CModal visible={visible} onClose={closeModal} alignment="top" size="md">
      <ListSPV
        visible={visibleListSpv}
        closeModal={handleClosePilihSpv}
        closeModalDetail={closeModal}
        dataDetail={dataDetail}
        refetch={refetchDetailNotif}
      />
      <CModalHeader
        component="h5"
        className="header-wrapper-manajemen-role"
        onClose={closeModal}
      >
        {" "}
        Pesan Notifikasi
      </CModalHeader>
      <CModalBody>
        <CRow>
          <div>
            {isLoadingGetDetailNotif ? (
              <div>
                <CSpinner />
              </div>
            ) : (
              <>
                <CRow>
                  <CCol>
                    <CFormLabel>Judul</CFormLabel>
                    <CFormInput value={dataDetail.title} disabled />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <CFormLabel>Pesan</CFormLabel>
                    <textarea
                      className="w-100 border rounded p-2"
                      rows="13"
                      value={dataDetail.message || ""}
                      name="message"
                      disabled
                    />
                  </CCol>
                </CRow>
              </>
            )}
          </div>
        </CRow>
        {dataDetail.supervisor_reservation === employeeId &&
          dataDetail.notif_type === "Reservasi" && (
            <CRow>
              <CCol className="d-flex justify-content-evenly gap-1">
                {dataDetail.status === "BELUM DICEK" && (
                  <CButton
                    className="btn-success w-100"
                    onClick={() =>
                      dataDetail.title === "PENAMBAHAN PAX RESERVASI PAKET"
                        ? handleAddPax(dataDetail.notification_id)
                        : handleCheckNotif(dataDetail.notification_id)
                    }
                  >
                    {dataDetail.type === "RESERVASI PAKET"
                      ? "Check Bahan"
                      : "Check"}
                  </CButton>
                )}
                <CButton className="btn-warning w-100" onClick={handlePilihSpv}>
                  Pindah Ke SPV Lain
                </CButton>
              </CCol>
            </CRow>
          )}
      </CModalBody>
    </CModal>
  );
};

export default DetailNotifikasi;
