import React from "react";

const TabletMode = React.lazy(() =>
  import("./views/kalasanPages/pengaturan/tabletMode/tabletMode")
);
const HistoriPenerimaan = React.lazy(() =>
  import("./views/kalasanPages/tablet/penerimaan/historiPenerimaan")
);
const Stock = React.lazy(() => import("./views/kalasanPages/tablet/stok/Stok"));
const PenerimaanUdangKelong = React.lazy(() =>
  import(
    "./views/kalasanPages/tablet/penerimaan/udang-kelong/penerimaanUdangKelong"
  )
);
const PenerimaanAyamSate = React.lazy(() =>
  import("./views/kalasanPages/tablet/penerimaan/ayam-sate/penerimaanAyamSate")
);
const PenerimaanUdangGalah = React.lazy(() =>
  import(
    "./views/kalasanPages/tablet/penerimaan/udang-galah/penerimaanUdangGalah"
  )
);
const PenerimaanCumi = React.lazy(() =>
  import("./views/kalasanPages/tablet/penerimaan/cumi/penerimaanCumi")
);
const PenerimaanDLL = React.lazy(() =>
  import("./views/kalasanPages/tablet/penerimaan/dll/penerimaanDLL")
);
const PenerimaanIkanKakap = React.lazy(() =>
  import(
    "./views/kalasanPages/tablet/penerimaan/ikan-kakap/penerimaanIkanKakap"
  )
);

const routesKitchen = [
  {
    path: "/tablet-mode",
    exact: true,
    name: "Mode Tablet",
    component: TabletMode,
  },
  {
    path: "/tablet-mode/histori-penerimaan",
    name: "Histori Penerimaan",
    component: HistoriPenerimaan,
  },
  {
    path: "/tablet-mode/stok",
    name: "Stok Tablet",
    component: Stock,
  },
  {
    path: "/tablet-mode/penerimaan-udang-kelong",
    name: "Penerimaan Udang Kelong",
    component: PenerimaanUdangKelong,
  },
  {
    path: "/tablet-mode/penerimaan-ayam-sate",
    name: "Penerimaan Ayam Sate",
    component: PenerimaanAyamSate,
  },
  {
    path: "/tablet-mode/penerimaan-udang-galah",
    name: "Penerimaan Udang Galah",
    component: PenerimaanUdangGalah,
  },
  {
    path: "/tablet-mode/penerimaan-cumi",
    name: "Penerimaan Cumi",
    component: PenerimaanCumi,
  },
  {
    path: "/tablet-mode/penerimaan-dll",
    name: "Penerimaan DLL",
    component: PenerimaanDLL,
  },
  {
    path: "/tablet-mode/penerimaan-ikan-kakap",
    name: "Penerimaan Ikan Kakap",
    component: PenerimaanIkanKakap,
  },
];

export default routesKitchen;
