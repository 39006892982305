/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import axios from "axios";
import React, { useRef } from "react";
import {
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
  CButton,
} from "@coreui/react-pro";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert";
import { useHistory } from "react-router";
import { logout } from "src/configs/redux/action/userAction";
import { useFormik } from "formik";
import * as Yup from "yup";
import CIcon from "@coreui/icons-react";
import { cilSave, cilTrash } from "@coreui/icons";

const ChangePIN = ({ nip, handleClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector((state) => state.user.profile.token);

  const inputRef = useRef(null);

  if (inputRef.current) {
    inputRef.current.focus();
  }

  const formik = useFormik({
    initialValues: {
      pin: 0,
      isDeletePressed: false,
    },
    onSubmit: (values) => {
      let validate = true;

      if (!values.isDeletePressed && (!values.pin || values.pin === "0")) {
        validate = false;
        Swal("PIN kosong tidak bisa melakukan simpan!", "", "error");
      }

      if (validate) {
        Swal("Yakin ingin mengubah PIN?", {
          dangerMode: true,
          cancel: true,
          buttons: true,
          icon: "warning",
        }).then((result) => {
          if (result) {
            axios
              .patch(
                `${process.env.REACT_APP_BASE_URL}/account/pin/${nip}`,
                values,
                {
                  headers: {
                    access_token: `${token}`,
                  },
                }
              )
              .then(() => {
                handleClose();
                dispatch(logout(history));
                if (values.isDeletePressed) {
                  Swal("PIN berhasil dihapus!", "", "success");
                } else {
                  Swal("PIN berhasil diubah!", "", "success");
                }
              })
              .catch((err) => {
                if (err.response) {
                  if (err.response.status === 401) {
                    dispatch(logout(history));
                    Swal("Session Expired", "", "error");
                  } else {
                    Swal(
                      err?.response?.data?.message || "Ubah PIN gagal!",
                      "",
                      "error"
                    );
                  }
                } else if (err.request) {
                  Swal("Error Occured. Please Try Again Later!", "", "error");
                } else {
                  Swal("Error Occured. Please Try Again Later!", "", "error");
                }
              });
          }
        });
      }
    },
  });

  return (
    <>
      <CForm
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}
      >
        <CRow>
          <CCol md={12}>
            <div className="mb-3">
              <CFormLabel htmlFor="pin">PIN baru</CFormLabel>
              <CFormLabel className="text-danger">*</CFormLabel>

              <CFormInput
                className={formik.errors.pin ? "is-invalid" : ""}
                name="pin"
                type="password"
                id="pin"
                placeholder="PIN"
                onChange={formik.handleChange}
                onKeyPress={(e) => {
                  if (!/[0-9,]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                // ref={inputRef}
                onFocus={(e) => e.target.select()}
              />
              {formik.errors.pin && formik.touched.pin && (
                <CFormLabel style={{ color: "red" }}>
                  {formik.errors.pin}
                </CFormLabel>
              )}
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol md={12}>
            <div className="d-grid gap-2 d-md-flex">
              <CButton
                className="me-md-2"
                style={{
                  backgroundColor: "white",
                  color: "red",
                  outline: "1px solid red",
                }}
                onClick={() => {
                  formik.setFieldValue("isDeletePressed", true);
                  formik.handleSubmit();
                }}
                title="Hapus PIN pengguna"
              >
                <CIcon icon={cilTrash} />
                Hapus
              </CButton>
              <CButton
                color="primary"
                className="me-md-2"
                type="submit"
                title="Simpan PIN baru"
                onClick={() => {
                  formik.setFieldValue("isDeletePressed", false);
                }}
              >
                <CIcon icon={cilSave} />
                Simpan
              </CButton>
            </div>
          </CCol>
        </CRow>
      </CForm>
    </>
  );
};

export default ChangePIN;
