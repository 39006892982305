import React from "react";
import CIcon from "@coreui/icons-react";
import { cilAccountLogout } from "@coreui/icons";
import { AppContentKitchen } from "../components/index";
import kalasanLogo from "src/assets/brand/kalasan-logo.png";
import Swal from "sweetalert";
import { logout } from "src/configs/redux/action/userAction";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const KitchenLayout = () => {
  return (
    <>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
        <div className={`body flex-grow-1`}>
          <AppContentKitchen />
        </div>
      </div>
    </>
  );
};

export default KitchenLayout;
