import React from "react";
import { AppContentWaitress } from "../components/index";

const WaitressLayout = () => {
  return (
    <>
      <div className="wrapper d-flex flex-column bg-light dark:bg-transparent">
        {/* <HeaderWaitress /> */}
        <div className="body">
          <AppContentWaitress />
        </div>
      </div>
    </>
  );
};

export default WaitressLayout;
