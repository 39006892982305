import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormLabel,
  CModal,
  CModalBody,
  CModalHeader,
  CButton,
} from "@coreui/react-pro";
import { cilBell } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import Swal from "sweetalert";
import { useHistory } from "react-router";
import { logout } from "src/configs/redux/action/userAction";

import ChangePassword from "src/views/kalasanPages/pengaturan/ChangePassword";
import { useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import HistoriAPI from "src/views/kalasanPages/histori/APIHistori";
import ResponseError from "src/views/kalasanPages/penjualan/waitress/waitressComponents/responseError";
import DetailNotifikasi from "src/views/kalasanPages/histori/notifikasi/notifikasiComponent/DetailNotifikasi";
import { encode } from "base-64";
import { io } from "socket.io-client";
import useSound from "use-sound";
import NotifBantuan from "../../assets/audio/notif-bantuan.mp3";

const AppHeaderDropdown = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector((state) => state.user.profile.name);
  const role = useSelector((state) => state.user.profile.role);
  const nip = useSelector((state) => state.user.profile.nip);
  const token = useSelector((state) => state.user.profile.token);
  const ava = useSelector(
    (state) => state.user.profile.profile_picture_path_filename
  );
  const employeeId = useSelector((state) => state.user.profile.employee_id);
  const pathName = window.location.pathname;
  const socket = useRef(null);
  const [showDetail, setShowDetail] = useState(false);
  const [notifId, setNotifId] = useState("");

  const [notification, setNotification] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalNotification, setTotalNotification] = useState(0);
  const [visibleDetail, setVisibleDetail] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownToggleRef = useRef(null);
  const [playSound, setPlaySound] = useState(false);
  const [playBantuan] = useSound(NotifBantuan);

  const { isLoading: isLoadingGetNotif, refetch: refetchNotif } = useQuery(
    ["get-notif-all", employeeId, pageSize],
    () =>
      HistoriAPI.getAllNotifPagination({
        token,
        employee: employeeId,
        projectPerPage: pageSize,
        admin: true,
      }),
    {
      onSuccess: (res) => {
        if (res.data) {
          setNotification(res.data);
          setTotalNotification(res.meta.totalData);
        }
      },
      onError: (err) => {
        ResponseError(err, dispatch, history);
      },
    }
  );

  const { isLoading: isLoadingReadNotif, mutate: updateIsReadNotif } =
    useMutation(HistoriAPI.readNotif, {
      onSuccess: () => {
        refetchNotif();
      },
      onError: (err) => {
        ResponseError(err, dispatch, history);
      },
    });

  const onLogOut = (e) => {
    Swal("Yakin keluar aplikasi?", {
      dangerMode: true,
      cancel: true,
      buttons: true,
      icon: "info",
    }).then((result) => {
      if (result) {
        dispatch(logout(history));
      }
    });
  };

  if (playSound) {
    playBantuan();
    setPlaySound(false);
  }

  const editPass = () => {
    setShowDetail(true);
  };
  const closeEdit = () => {
    setShowDetail(false);
  };
  const handleClose = (data) => {
    setShowDetail(data);
  };

  const handleViewMore = () => {
    setPageSize((prev) => prev + 10);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(true);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const handleShowDetailNotifikasi = (dataDetail) => {
    setNotifId(dataDetail.notification_id);
    updateIsReadNotif({
      token,
      notifId: encode(dataDetail.notification_detail_id),
    });
    setVisibleDetail(true);
  };

  const closeDetailModal = () => {
    setNotifId("");
    setVisibleDetail(false);
    refetchNotif();
  };

  useEffect(() => {
    const url = `${process.env.REACT_APP_BASE_URL}/orders`;

    if (pathName === "/sales/order") {
      socket.current = io(url, {
        "force new connection": true,
        reconnectionAttempts: "Infinity",
        timeout: 10000,
        secure: true,
        auth: { access_token: token },
        transports: ["websocket", "polling"],
      });

      socket.current.on("notif", (data) => {
        refetchNotif();
      });

      socket.current.on("error", (err) => {});

      socket.current.on("disconnect", () => {
        socket.current.disconnect();
      });
    }
  }, []);

  useEffect(() => {
    const url = `http://${process.env.REACT_APP_SERVER_SOCKET}:8011`;

    const socket = io(url, {
      reconnection: false,
    });

    socket.on("connect", () => {
      console.log("Connected to Socket.IO server frontend");
    });

    socket.emit("login", employeeId);

    socket.on("check_notif", (data, type) => {
      if (type && type === "bantuan") {
        setPlaySound(true);
        navigator.vibrate(1000);
      }
      refetchNotif();
    });

    socket.on("disconnect", () => {
      socket.disconnect();
    });
  }, []);

  return (
    <>
      <DetailNotifikasi
        id={encode(notifId)}
        visible={visibleDetail}
        closeModal={closeDetailModal}
        refetch={refetchNotif}
      />
      {pathName !== "/history/notifikasi" && (
        <CDropdown
          className="mr-3 mt-1"
          variant="nav-item"
          alignment="end"
          onClick={toggleDropdown}
        >
          <CDropdownToggle
            placement="bottom-end"
            className="py-0 text-light profile-wrapper"
            caret={false}
            ref={dropdownToggleRef}
          >
            <CFormLabel className="fw-bolder">
              <CButton color="link" className="position-relative">
                <CIcon className="text-white" icon={cilBell} />
                {totalNotification > 0 && (
                  <CBadge
                    color="danger"
                    position="top-end"
                    shape="rounded-pill"
                  >
                    {totalNotification}
                  </CBadge>
                )}
              </CButton>
            </CFormLabel>
          </CDropdownToggle>
          {isDropdownOpen && (
            <CDropdownMenu className="mt-0 pt-0 nav-top-header-account">
              {notification.length > 0 ? (
                <>
                  {notification
                    // .slice(0, visibleNotifications)
                    .map((item, index) => (
                      <CDropdownItem
                        className="d-flex justify-content-between"
                        key={index}
                        style={{
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "400px",
                        }}
                        onClick={() => handleShowDetailNotifikasi(item)}
                      >
                        {truncateText(item.message, 100)}
                      </CDropdownItem>
                    ))}
                  {totalNotification !== notification.length && (
                    <CButton
                      color="success"
                      className="w-100 mt-2 text-white"
                      onClick={handleViewMore}
                    >
                      Lihat lebih banyak
                    </CButton>
                  )}
                </>
              ) : (
                <p
                  className="d-flex justify-content-center text-white"
                  style={{
                    width: "400px",
                  }}
                >
                  Tidak ada notifikasi baru
                </p>
              )}
            </CDropdownMenu>
          )}
        </CDropdown>
      )}

      <CModal
        visible={showDetail}
        onClose={closeEdit}
        alignment="center"
        size="lg"
        keyboard={false}
      >
        <CModalHeader
          component="h5"
          className="header-wrapper-manajemen-role"
          onClose={closeEdit}
        >
          {" "}
          Edit Password
        </CModalHeader>
        <CModalBody>
          <ChangePassword nip={nip} handleClose={handleClose} />
        </CModalBody>
      </CModal>
    </>
  );
};

export default AppHeaderDropdown;
