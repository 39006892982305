/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  CCol,
  CRow,
  CFormLabel,
  CFormInput,
  CForm,
  CButton,
} from "@coreui/react-pro";
import { useSelector } from "react-redux";
import Swal from "sweetalert";
import { useHistory } from "react-router";
import { logout } from "src/configs/redux/action/userAction";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import CIcon from "@coreui/icons-react";
import { cilSave } from "@coreui/icons";

const ChangePassword = ({ nip, handleClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [dataDetail, setDataDetail] = useState("");
  const token = useSelector((state) => state.user.profile.token);
  const profile = useSelector((state) => state.user.profile.name);
  const birth = useSelector((state) => state.user.profile.birth_date);
  const phone = useSelector((state) => state.user.profile.phone);
  const address = useSelector((state) => state.user.profile.address);

  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: profile,
      birth_date: birth,
      phone: phone,
      address: address,
      current_password: "",
      password: "",
    },
    validationSchema: Yup.object({
      current_password: Yup.string().required("Required"),
      password: Yup.string().required("Required").min(6, "Min 6 Characters"),
    }),
    onSubmit: (values) => {
      Swal("Yakin data akan disimpan?", {
        dangerMode: true,
        cancel: true,
        buttons: true,
        icon: "warning",
      }).then((result) => {
        if (result) {
          axios
            .post(`${process.env.REACT_APP_BASE_URL}/account/`, values, {
              headers: {
                access_token: `${token}`,
              },
            })
            .then((response) => {
              Swal("Password berhasil diubah!", "", "success");
              handleClose(false);
            })
            .catch((err) => {
              if (err.response) {
                // Request made and server responded

                if (err.response.status === 401) {
                  dispatch(logout(history));
                  Swal("Session Expired", "", "error");
                } else {
                  Swal(
                    err?.response?.data?.message || "ubah passowrd gagal!",
                    "",
                    "error"
                  );
                }
              } else if (err.request) {
                // The request was made but no response was received

                Swal("Error Occured. Please Try Again Later!", "", "error");
              } else {
                // Something happened in setting up the request that triggered an Error

                Swal("Error Occured. Please Try Again Later!", "", "error");
              }
            });
        }
      });
    },
  });
  //     setLoading(true);
  //     axios
  //       .get(`${process.env.REACT_APP_BASE_URL}/product/${id}`, {
  //         headers: {
  //           access_token: `${token}`,
  //         },
  //       })
  //       .then((response) => {
  //         setLoading(false);
  //         setDataDetail(response.data.data);
  //         setLoading(false);
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //         if (err.response) {
  //           // Request made and server responded
  //
  //
  //
  //           if (err.response.status === 401) {
  //             dispatch(logout(history));
  //             Swal("Session Expired", "", "warning");
  //           }
  //         } else if (err.request) {
  //           // The request was made but no response was received
  //
  //           Swal("Error Occured. Please Try Again Later!", "", "error");
  //           // setLoading(false);
  //         } else {
  //           // Something happened in setting up the request that triggered an Error
  //
  //           Swal("Error Occured. Please Try Again Later!", "", "error");
  //           // setLoading(false);
  //         }
  //       });
  //   }, []);
  //   let dataSupplier = dataDetail.supplier;
  //   let no = 1;

  return (
    <>
      <CForm
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}
      >
        <CRow>
          <CCol md={6}>
            <div className="mb-3">
              <CFormLabel htmlFor="current_password">
                Current Password
              </CFormLabel>
              <CFormLabel className="text-danger">*</CFormLabel>

              <CFormInput
                className={formik.errors.current_password ? "is-invalid" : ""}
                name="current_password"
                type="password"
                id="current_password"
                placeholder="Current Password"
                onChange={formik.handleChange}
              />
              {formik.errors.current_password &&
                formik.touched.current_password && (
                  <CFormLabel style={{ color: "red" }}>
                    {formik.errors.current_password}
                  </CFormLabel>
                )}
            </div>
          </CCol>
          <CCol md={6}>
            <div className="mb-3">
              <CFormLabel htmlFor="password">New Password</CFormLabel>
              <CFormLabel className="text-danger">*</CFormLabel>

              <CFormInput
                className={formik.errors.password ? "is-invalid" : ""}
                name="password"
                type="password"
                id="password"
                placeholder="Password"
                onChange={formik.handleChange}
              />
              {formik.errors.password && formik.touched.password && (
                <CFormLabel style={{ color: "red" }}>
                  {formik.errors.password}
                </CFormLabel>
              )}
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol md={12}>
            <div className="d-grid gap-2 d-md-flex">
              <CButton color="primary" className="me-md-2" type="submit">
                <CIcon icon={cilSave} />
                Simpan
              </CButton>
            </div>
          </CCol>
        </CRow>
      </CForm>
    </>
  );
};

export default ChangePassword;
