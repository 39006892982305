import React from "react";
// import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  // CButtonGroup,
  // CFormCheck,
  CContainer,
  CHeader,
  CHeaderBrand,
  // CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  // CNavLink,
  // CNavItem,
} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import {
  // cilApplicationsSettings,
  cilMenu,
  // cilMoon,
  // cilSun,
} from "@coreui/icons";

// import { AppBreadcrumb } from "./index";

import {
  AppHeaderDropdown,
  AppHeaderNotifcation,
  // AppHeaderDropdownMssg,
  // AppHeaderDropdownNotif,
  // AppHeaderDropdownTasks,
} from "./header/index";

import kalasanLogo from "src/assets/brand/kalasan-logo.png";
import AppHeaderOutlet from "./header/AppHeaderOutlet";

const AppHeader = () => {
  const dispatch = useDispatch();

  const theme = useSelector((state) => state.interface.theme);
  const role = useSelector((state) => state.user.profile.role);

  theme === "dark"
    ? document.body.classList.add("dark-theme")
    : document.body.classList.remove("dark-theme");

  const sidebarShow = useSelector((state) => state.interface.sidebarShow);
  // const asideShow = useSelector((state) => state.interface.asideShow);
  // const pathname = window.location.pathname; //returns the current url minus the domain name
  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer
        fluid
        className="tab-version
      "
      >
        <CHeaderToggler
          className="ps-1"
          onClick={() => {
            dispatch({ type: "set", sidebarShow: !sidebarShow });
          }}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none" to="/">
          <img
            className="sidebar-brand-full"
            src={kalasanLogo}
            height={50}
            alt="kalasanLogo"
          />
        </CHeaderBrand>

        <CHeaderNav className="ms-3">
          {role === "SUPERUSER" && <AppHeaderOutlet />}
          <AppHeaderNotifcation />
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
    </CHeader>
  );
};

export default AppHeader;
