import React from "react";
import { AppContentFastFood } from "src/components";

const FastFoodLayout = () => {
  return (
    <>
      <div className="wrapper d-flex flex-column bg-light dark:bg-transparent">
        {/* <HeaderWaitress /> */}
        <div className="body">
          <AppContentFastFood />
        </div>
      </div>
    </>
  );
};

export default FastFoodLayout;
