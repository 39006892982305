import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  // CSidebarToggler,
  CNavGroup,
  CNavItem,
} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
// import { Icon } from "@iconify/react";

import { AppSidebarNav } from "./AppSidebarNav";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
// import axios from "axios";
// sidebar nav config
import Swal from "sweetalert";
import { useHistory } from "react-router";
import { logout } from "src/configs/redux/action/userAction";
import kalasanLogo from "src/assets/brand/kalasan-logo.png";
import { cilAccountLogout } from "@coreui/icons";

const AppSidebar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const menu = useSelector((state) => state.user.profile.permission);
  const unfoldable = useSelector((state) => state.interface.sidebarUnfoldable);
  const sidebarShow = useSelector((state) => state.interface.sidebarShow);
  const [dataMenu, setDataMenu] = useState([]);

  const functionMenu = () => {
    let data = [
      {
        component: CNavItem,
        name: "Dashboard",
        icon: <i className="fas fa-tachometer-alt nav-icon" />,
        to: "/dashboard",
      },
    ];
    menu.map((value) => {
      if (value.isParent && value.child) {
        let dataParent = "";
        if (value.child.length > 0) {
          dataParent = {
            component: CNavGroup,
            name: `${value.name}`,
            icon: <i className={`${value.icon} nav-icon`} />,
            items: [],
          };
          let child = value.child;
          child.map((valueChild) => {
            let dataChild = {
              component: CNavItem,
              name: `${valueChild.name}`,
              to: `${valueChild.url}`,
            };
            return dataParent.items.push(dataChild);
          });
          // data.push(dataParent);
        } else {
          dataParent = {
            component: CNavItem,
            name: `${value.name}`,
            icon: <i className={`${value.icon} nav-icon`} />,
            to: `${value.url}`,
          };
        }
        data.push(dataParent);
      }
    });

    setDataMenu(data);
  };

  useEffect(() => {
    functionMenu();
  }, []);

  const onLogOut = (e) => {
    Swal("Yakin keluar aplikasi?", {
      dangerMode: true,
      cancel: true,
      buttons: true,
      icon: "info",
    }).then((result) => {
      if (result) {
        dispatch(logout(history));
      }
    });
  };

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: "set", sidebarShow: visible });
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <img
          className="sidebar-brand-full"
          src={kalasanLogo}
          height={35}
          alt="kalasanLogo-logo"
        />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          {" "}
          {dataMenu ? <AppSidebarNav items={dataMenu} /> : <></>}{" "}
        </SimpleBar>
      </CSidebarNav>
      <div className="logout mb-5" onClick={() => onLogOut()}>
        <CIcon icon={cilAccountLogout} customClassName="nav-icon-logout" /> Log
        Out
      </div>
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
