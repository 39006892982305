import React, { Component } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { CSpinner } from "@coreui/react-pro";
import "./scss/style.scss";
import "./scss/style-kalasan-erp.scss";
import PublicRoute from "./configs/routes/PublicRoutes";
import PrivateRoute from "./configs/routes/PrivateRoutes";
import WaitressLayout from "./layout/WaitressLayout";
import KasirLayout from "./layout/KasirLayout";
import KitchenLayout from "./layout/KitchenLayout";
import FastFoodLayout from "./layout/FastFoodLayout";

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
// const Register = React.lazy(() => import("./views/pages/register/Register"));
// const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
// const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

// // Email App
// const EmailApp = React.lazy(() => import("./views/apps/email/EmailApp"));

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={<CSpinner color="primary" />}>
          <Switch>
            <PublicRoute
              exact
              path="/login"
              name="Login Page"
              component={Login}
              // render={(props) => <Login {...props} />}
            />

            <PrivateRoute
              path="/sales/order"
              name="Waitress"
              component={WaitressLayout}
              // render={(props) => <DefaultLayout {...props} />}
            />

            <PrivateRoute
              path="/sales/cashier"
              name="Kasir"
              component={KasirLayout}
              // render={(props) => <DefaultLayout {...props} />}
            />

            <PrivateRoute
              path="/sales/fast-food"
              name="Fast Food"
              component={FastFoodLayout}
            />

            <PrivateRoute
              path="/tablet-mode"
              name="Kitchen"
              component={KitchenLayout}
            />

            <PrivateRoute
              path="/"
              name="Home"
              component={DefaultLayout}
              // render={(props) => <DefaultLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
