import React from "react";

// const Waitress = React.lazy(() =>
//   import("./views/kalasanPages/penjualan/waitress/Waitress")
// );
// const WaitressOnGoing = React.lazy(() =>
//   import("./views/kalasanPages/penjualan/waitress/OnGoing")
// );
// const WaitressStatus = React.lazy(() =>
//   import("./views/kalasanPages/penjualan/waitress/Status")
// );

const Waitress = React.lazy(() =>
  import("./views/kalasanPages/penjualan/waitressRevamp/WaitressRevamp")
);

const routesWaitress = [
  {
    path: "/sales/order",
    exact: true,
    name: "Waitress",
    component: Waitress,
  },
  // {
  //   path: "/sales/order/on-going",
  //   exact: true,
  //   name: "Waitress On Going",
  //   component: WaitressOnGoing,
  // },
  // {
  //   path: "/sales/order/status",
  //   exact: true,
  //   name: "Waitress Status",
  //   component: WaitressStatus,
  // },
];

export default routesWaitress;
