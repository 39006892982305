import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CAvatar,
  CBadge,
  CDropdown,
  // CDropdownDivider,
  // CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormLabel,
  CModal,
  CModalBody,
  CModalHeader,
  CRow,
  CCol,
  CButton,
} from "@coreui/react-pro";
import {
  cilBell,
  // cilCreditCard,
  // cilCommentSquare,
  // cilEnvelopeOpen,
  // cilFile,
  cilLockLocked,
  // cilSettings,
  // cilTask,
  // cilUser,
  cilAccountLogout,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import Swal from "sweetalert";
import { useHistory } from "react-router";
import { logout } from "src/configs/redux/action/userAction";
import axios from "axios";

import ChangePassword from "src/views/kalasanPages/pengaturan/ChangePassword";
import { useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import HistoriAPI from "src/views/kalasanPages/histori/APIHistori";
import ResponseError from "src/views/kalasanPages/penjualan/waitress/waitressComponents/responseError";
import DetailNotifikasi from "src/views/kalasanPages/histori/notifikasi/notifikasiComponent/DetailNotifikasi";
import { encode } from "base-64";

const AppHeaderDropdown = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector((state) => state.user.profile.name);
  const role = useSelector((state) => state.user.profile.role);
  const nip = useSelector((state) => state.user.profile.nip);
  const token = useSelector((state) => state.user.profile.token);
  const ava = useSelector(
    (state) => state.user.profile.profile_picture_path_filename
  );
  const employeeId = useSelector((state) => state.user.profile.employee_id);
  const pathName = window.location.pathname;
  const outlet = useSelector((state) => state.user.profile.outlet);
  const [showDetail, setShowDetail] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [notifId, setNotifId] = useState("");

  const [notification, setNotification] = useState([]);
  const [visibleNotifications, setVisibleNotifications] = useState(3);
  const [visibleDetail, setVisibleDetail] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownToggleRef = useRef(null);

  const { isLoading: isLoadingGetNotif, refetch: refetchNotif } = useQuery(
    ["get-notif-all", employeeId],
    () =>
      HistoriAPI.getAllNotif({
        token,
        employee: employeeId,
      }),
    {
      onSuccess: (res) => {
        if (res.data) {
          setNotification(res.data);
        }
      },
      onError: (err) => {
        ResponseError(err, dispatch, history);
      },
    }
  );

  const { isLoading: isLoadingReadNotif, mutate: updateIsReadNotif } =
    useMutation(HistoriAPI.readNotif, {
      onSuccess: () => {
        refetchNotif();
      },
      onError: (err) => {
        ResponseError(err, dispatch, history);
      },
    });

  const onLogOut = (e) => {
    Swal("Yakin keluar aplikasi?", {
      dangerMode: true,
      cancel: true,
      buttons: true,
      icon: "info",
    }).then((result) => {
      if (result) {
        dispatch(logout(history));
      }
    });
  };

  const editPass = () => {
    setShowDetail(true);
  };
  const closeEdit = () => {
    setShowDetail(false);
  };
  const handleClose = (data) => {
    setShowDetail(data);
  };

  const handleViewMore = () => {
    setIsDropdownOpen(false);
    history.push("/history/notifikasi");
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(true);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const handleShowDetailNotifikasi = (dataDetail) => {
    setNotifId(dataDetail.notification_id);
    updateIsReadNotif({
      token,
      notifId: encode(dataDetail.notification_detail_id),
    });
    setVisibleDetail(true);
  };

  const closeDetailModal = () => {
    setNotifId("");
    setVisibleDetail(false);
    refetchNotif();
  };

  useEffect(() => {
    refetchNotif();
  }, [pathName]);

  return (
    <>
      <DetailNotifikasi
        id={encode(notifId)}
        visible={visibleDetail}
        closeModal={closeDetailModal}
        refetch={refetchNotif}
      />
      {pathName !== "/history/notifikasi" && (
        <CDropdown
          className="mr-3 mt-1"
          variant="nav-item"
          alignment="end"
          onClick={toggleDropdown}
        >
          <CDropdownToggle
            placement="bottom-end"
            className="py-0 text-light profile-wrapper"
            caret={false}
            ref={dropdownToggleRef}
          >
            <CFormLabel className="fw-bolder">
              <CButton color="link" className="position-relative">
                <CIcon className="text-white" icon={cilBell} />
                {notification.length > 0 && (
                  <CBadge
                    color="danger"
                    position="top-end"
                    shape="rounded-pill"
                  >
                    {notification.length}
                  </CBadge>
                )}
              </CButton>
            </CFormLabel>
          </CDropdownToggle>
          {isDropdownOpen && (
            <CDropdownMenu className="mt-0 pt-0 nav-top-header-account">
              {notification.length > 0 ? (
                <>
                  {notification
                    // .slice(0, visibleNotifications)
                    .map((item, index) => (
                      <CDropdownItem
                        className="d-flex justify-content-between"
                        key={index}
                        style={{
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "400px",
                        }}
                        onClick={() => handleShowDetailNotifikasi(item)}
                      >
                        {truncateText(item.message, 100)}
                      </CDropdownItem>
                    ))}
                  {/* {visibleNotifications < notification.length && (
                    <CButton className="w-100 mt-2" onClick={handleViewMore}>
                      View More
                    </CButton>
                  )} */}
                </>
              ) : (
                <p
                  className="d-flex justify-content-center text-white"
                  style={{
                    width: "400px",
                  }}
                >
                  Tidak ada notifikasi baru
                </p>
              )}
            </CDropdownMenu>
          )}
        </CDropdown>
      )}

      <CModal
        visible={showDetail}
        onClose={closeEdit}
        alignment="center"
        size="lg"
        keyboard={false}
      >
        <CModalHeader
          component="h5"
          className="header-wrapper-manajemen-role"
          onClose={closeEdit}
        >
          {" "}
          Edit Password
        </CModalHeader>
        <CModalBody>
          <ChangePassword nip={nip} handleClose={handleClose} />
        </CModalBody>
      </CModal>
    </>
  );
};

export default AppHeaderDropdown;
