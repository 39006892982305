import axios from "axios";

const API = {
  //PROVINCE
  getAllProvince: async ({ token }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/province`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },
  //====================

  //CITY
  getAllCityByProvince: async ({ token, id }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/city/${id}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },
  //====================

  //OUTLET REQUEST
  getAllOutletRequest: async ({ token }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/outletRequest/all`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  getRequestDetail: async ({ token, id }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/outletRequestDetail?requestId=${id}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },
  //====================

  //SPK
  getAllSPK: async ({ token, division_id = "", outlet_id = "", date }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/spkProduction?division=${division_id}&outlet=${outlet_id}&startDate=${date.dateFrom}&endDate=${date.dateTo}&`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  getSPKById: async ({ token, id }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/spkProduction/${id}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  updateSPK: async ({ token, id, payload }) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/spkProduction/${id}`,
      payload,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  createSPK: async ({ token, payload }) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/spkProduction`,
      payload,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  prosesSPK: async ({ token, id }) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/spkProduction/proses/${id}`,
      {},
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  deleteSPK: async ({ token, id }) => {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/spkProduction/${id}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  //====================

  //LAPORAN PENJUALAN
  getDetailPenjualan: async ({ token, order_code, menu }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/orders/menuReports/${order_code}?menu=${menu}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  //====================

  //REQUEST TIME
  getAllReceiveProduct: async ({ token }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/receive-product`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  getReceiveProductById: async ({ token, id }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/receive-product/${id}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  createReceiveProduct: async ({ token, payload }) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/receive-product`,
      payload,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  updateReceiveProduct: async ({ token, id, payload }) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/receive-product/${id}`,
      payload,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  deleteReceiveProduct: async ({ token, id }) => {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/receive-product/${id}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },
  //====================

  //WEIGHT SCALE
  getAllWeightScale: async ({ token }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/weight-scale`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  getWeightScaleById: async ({ token, id }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/weight-scale/${id}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  createWeightScale: async ({ token, payload }) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/weight-scale`,
      payload,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  updateWeightScale: async ({ token, payload, id }) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/weight-scale/${id}`,
      payload,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  deleteWeightScale: async ({ token, id }) => {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/weight-scale/${id}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },
  //====================

  verifyPin: async ({ token, payload }) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/users/verifypin`,
      payload,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  //OUTLET
  getAllOutlet: async ({ token }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/outlet/all`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },
  //====================

  //DIVISI
  getDivisiByOutlet: async ({ token }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/division/all`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },
  //====================

  //WAREHOUSE
  getAllWarehouseNoPagination: async ({ token }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/warehouse/all`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  getWarehouseByPIC: async ({ token }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/warehouse/getbyNip`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },
  //====================

  //MODUL
  getAllModulNonPaginated: async ({ token }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/menu/all`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },
  //====================

  //PRODUCT
  getAllProduct: async ({
    token,
    category,
    supplier,
    warehouse,
    currentPage,
    projectPerPage,
    field,
    sort,
    search,
  }) => {
    const { data } = await axios.get(
      `${
        process.env.REACT_APP_BASE_URL
      }/product?category=${category}&supplierQ=${supplier}&warehouse=${warehouse}&currentPage=${currentPage}&pageSize=${projectPerPage}&sort=${field}&sortType=${sort}${
        search ? `&search=${search}` : ""
      }`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  getAllProductNonPaginated: async ({ token }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/product/all`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  getStockInformation: async ({ token }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/dstock-information`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  getParentProduct: async ({ token }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/product/getBySupplier/0`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  deleteProduct: async ({ token, id }) => {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/product/${id}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  importProduk: async ({ token, payload }) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/productImport`,
      payload,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  getProductByWarehouse: async ({ token, warehouseId }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/config-warehouse-product/product?warehouse=${warehouseId}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },
  //====================

  //SUPPLIER
  getAllSupplierPaginated: async ({
    token,
    currentPage,
    projectPerPage,
    field,
    sort,
    search,
  }) => {
    const { data } = await axios.get(
      `${
        process.env.REACT_APP_BASE_URL
      }/supplier?currentPage=${currentPage}&pageSize=${projectPerPage}&sort=${field}&sortType=${sort}${
        search ? `&search=${search}` : ""
      }`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  getAllSupplier: async ({ token }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/supplier_getSelectOption`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  getSupplierAccount: async ({ token, id }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/supplier/account/${id}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  createAccountSupplier: async ({ token, id }) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/supplier/account/${id}`,
      {},
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  createProductSupplier: async ({ token, payload }) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/supplier/product`,
      payload,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  updateSupplier: async ({ token, payload, id }) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/supplier/${id}`,
      payload,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  updateHarga: async ({ token, payload, id }) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/supplier/price/${id}`,
      payload,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  deleteProductSupplier: async ({ token, id }) => {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/supplier/product/${id}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  deleteSupplier: async ({ token, id }) => {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/supplier/${id}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },
  //====================

  //ROLE
  getAllRole: async ({ token }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/role/all`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },
  //====================

  //MENU
  menu: async ({ token }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/waitressV3/allMenuCategory`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  menuAdmin: async ({ token }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/waitressV3/allMenuCategoryAdmin`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  createMenuGuide: async ({ token, payload }) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/menu-guide`,
      payload,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  updateMenuGuide: async ({ token, payload, id }) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/menu-guide/${id}`,
      payload,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  deleteMenuGuide: async ({ token, id }) => {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/menu-guide/${id}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  menuGuide: async ({ token }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/menu-guide`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  menuGuideByPax: async ({ token, pax, guide, lead }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/menu-guide-pax?pax=${pax}&guide=${guide}&lead=${lead}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },
  //====================

  //MASTER DATA
  getMasterDataByType: async ({ token, type }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/masterData/type/${type}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  createMasterData: async ({ token, payload }) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/masterData`,
      payload,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  updateMasterData: async ({ token, payload, masterDataId }) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/masterData/${masterDataId}`,
      payload,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  deleteMasterData: async ({ token, masterDataId }) => {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/masterData/${masterDataId}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },
  //====================

  //REPORT
  detailKredit: async ({ token, type }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/report/kredit?payment_type=${type}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },
  rekapPenjualan: async ({
    token,
    start,
    end,
    search,
    outlet,
    sort,
    sortType,
  }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/report/rekap-penjualan?search=${search}&outlet=${outlet}&startDate=${start}&endDate=${end}&sort=${sort}&sortType=${sortType}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },
  exportExcelRekapPenjualan: async ({
    token,
    start,
    end,
    currentPage,
    pageSize,
    search,
    outlet,
    sort,
    sortType,
  }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/report/export-rekap-penjualan?search=${search}&outlet=${outlet}&startDate=${start}&endDate=${end}&currentPage=${currentPage}&pageSize=${pageSize}&sort=${sort}&sortType=${sortType}`,
      {
        headers: {
          access_token: `${token}`,
        },
        responseType: "arraybuffer",
      }
    );

    return data;
  },
  rekapFastFood: async ({
    token,
    start,
    end,
    currentPage,
    pageSize,
    search,
    outlet,
  }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/report/rekap-fastfood?search=${search}&outlet=${outlet}&startDate=${start}&endDate=${end}&currentPage=${currentPage}&pageSize=${pageSize}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },
  reportMejaAktif: async ({
    token,
    room_code = "",
    table_code = "",
    order_code = "",
    outlet_id = "",
    sort = "",
    sortType = "",
    currentPage,
    projectPerPage,
  }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/report/meja-aktif?currentPage=${currentPage}&pageSize=${projectPerPage}&room_code=${room_code}&order_code=${order_code}&outlet_id=${outlet_id}&sort=${sort}&sortType=${sortType}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },
  //====================

  //EMPLOYEE
  getSupervisor: async ({ token }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/employeeBySupervisor`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  getByRole: async ({ token, role = "" }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/employeeDropdown?role=${role}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  getAllEmployeeOption: async ({ token }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/employee/all`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  //====================

  //AUDIT TRAIL
  getAllAuditTrail: async ({
    token,
    currentPage,
    projectPerPage,
    field,
    sort,
    date,
    search = "",
    filterAction,
  }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/auditTrail?filter=${filterAction}&sort=${field}&sortType=${sort}&date=${date}&search=${search}&currentPage=${currentPage}&pageSize=${projectPerPage}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  getDetailAuditTrail: async ({ token, id }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/auditTrail/${id}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },
  //====================

  //NOTIFICATION
  assignNotification: async ({ token, payload }) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/assign_notification`,
      payload,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  deleteAssignNotification: async ({ token, id }) => {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/assign_notification/${id}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  getAllAssignNotification: async ({ token }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/assign_notification`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  getAllNotifType: async ({ token }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/notification-type`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },
  //====================

  //TEMPORARY ACCESS
  createTemporaryAccess: async ({ token, payload }) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/temporary-access`,
      payload,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  getAllTemporaryAccess: async ({ token }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/temporary-access`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  deactivateAccessCode: async ({ token, tempAccessCodeId }) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/deactivate/temporary-access/${tempAccessCodeId}`,
      {},
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },
  //====================

  //LIST PRODUCTION
  getListProduction: async ({ token, division, outlet }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/outletListProduction?division=${division}&outlet=${outlet}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  createListProduction: async ({ token, payload }) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/outletListProduction`,
      payload,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  deleteListProduction: async ({ token, id }) => {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/outletListProduction/${id}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },
  //====================

  //REQUEST TIME
  getRequestTime: async ({ token }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/divisionRequestTime`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  getRequestTimeById: async ({ token, id }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/divisionRequestTime/${id}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  createRequestTime: async ({ token, payload }) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/divisionRequestTime`,
      payload,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  updateRequestTime: async ({ token, id, payload }) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/divisionRequestTime/${id}`,
      payload,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  deleteRequestTime: async ({ token, id }) => {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/divisionRequestTime/${id}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },
  //====================

  //STOK
  getAllStok: async ({
    token,
    currentPage,
    projectPerPage,
    search,
    warehouseId,
  }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/dstock?currentPage=${currentPage}&pageSize=${projectPerPage}&search=${search}&warehouseId=${warehouseId}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  getStockById: async ({ token, id }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/dstock/${id}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  getStockLog: async ({
    token,
    currentPage,
    projectPerPage,
    productId,
    warehouseId,
    startDate,
    endDate,
  }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/dstock-log?currentPage=${currentPage}&pageSize=${projectPerPage}&productId=${productId}&warehouseId=${warehouseId}&startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  inisiasiStok: async ({ token, payload }) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/dstock`,
      payload,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  getStokDetailQty: async ({ token, warehouse, product, qty }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/dstock-detail-qty?warehouseId=${warehouse}&productId=${product}&qty=${qty}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  //====================

  //PROBLEM REPORT
  createProblemReport: async ({ token, payload }) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/problem-report-bantuan`,
      payload,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  getAllProblemReport: async ({
    token,
    currentPage,
    projectPerPage,
    fetch,
    search,
    status,
    problemDate,
  }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/problem-report-bantuan?currentPage=${currentPage}&pageSize=${projectPerPage}&fetch=${fetch}&search=${search}&status=${status}&problemDate=${problemDate}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  processProblemReport: async ({ token, id }) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/problem-report-bantuan-process/${id}`,
      {},
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  closeProblemReport: async ({ token, id }) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/problem-report-bantuan-close/${id}`,
      {},
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },
  //====================

  //RACK
  getRackOption: async ({ token, product_id = "" }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/rack-dropdown?product=${product_id}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },
  //====================

  //ASSET
  getAssetOption: async ({ token }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/assetOption`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },
  //====================

  //MUTATION REQUEST
  getAllMutationRequest: async ({
    token,
    currentPage,
    pageSize,
    sort = "",
    sortType = "",
    search = "",
    requestDateStart = "",
    requestDateEnd = "",
    outletId = "",
  }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/mutationRequest?currentPage=${currentPage}&pageSize=${pageSize}&search=${search}&sort=${sort}&sortType=${sortType}&requestDateStart=${requestDateStart}&requestDateEnd=${requestDateEnd}&outlet=${outletId}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  getMutationRequestById: async ({ token, id }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/mutationRequest/${id}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  getMutationRequestDetailById: async ({ token, id }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/mutationRequest-detail/${id}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  createMutationRequest: async ({ token, payload }) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/mutationRequest`,
      payload,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  updateMutationRequest: async ({ token, payload, id }) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/mutationRequest/${id}`,
      payload,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  postingMutationRequest: async ({ token, id }) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/mutationRequest-posting/${id}`,
      {},
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  deleteMutationRequest: async ({ token, id }) => {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/mutationRequest/${id}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  processMutationRequest: async ({ token, id, payload }) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/mutationRequest-process/${id}`,
      payload,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  deliveryMutationRequest: async ({ token, id }) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/mutationRequest-delivery/${id}`,
      {},
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  receiveMutationRequest: async ({ token, payload, id }) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/mutationRequest-receive/${id}`,
      payload,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  rejectMutationRequest: async ({ token, id }) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/mutationRequest-reject/${id}`,
      {},
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },
  //====================
};

export default API;
