import React from "react";

const FastFood = React.lazy(() =>
  import("./views/kalasanPages/penjualan/fastFood/FastFood")
);

const routesFastFood = [
  {
    path: "/sales/fast-food",
    exact: true,
    name: "Fast Food",
    component: FastFood,
  },
];

export default routesFastFood;
