import React from "react";

const Kasir = React.lazy(() =>
  import("./views/kalasanPages/penjualan/kasir/KasirRevamp3")
);

const routesWaitress = [
  {
    path: "/sales/cashier",
    exact: true,
    name: "Kasir",
    component: Kasir,
  },
];

export default routesWaitress;
