import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CAvatar,
  // CBadge,
  CDropdown,
  // CDropdownDivider,
  // CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormLabel,
  CModal,
  CModalBody,
  CModalHeader,
} from "@coreui/react-pro";
import {
  // cilBell,
  // cilCreditCard,
  // cilCommentSquare,
  // cilEnvelopeOpen,
  // cilFile,
  cilLockLocked,
  cilDialpad,
  // cilSettings,
  // cilTask,
  // cilUser,
  cilAccountLogout,
  cilTablet,
  cilScreenDesktop,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import Swal from "sweetalert";
import { useHistory } from "react-router";
import { logout } from "src/configs/redux/action/userAction";

import ChangePassword from "src/views/kalasanPages/pengaturan/ChangePassword";
import ChangePIN from "src/views/kalasanPages/pengaturan/ChangePIN";

const AppHeaderDropdown = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector((state) => state.user.profile.name);
  const role = useSelector((state) => state.user.profile.role);
  const nip = useSelector((state) => state.user.profile.nip);
  const position = useSelector((state) => state.user.profile.position);
  const ava = useSelector(
    (state) => state.user.profile.profile_picture_path_filename
  );
  const outlet = useSelector((state) => state.user.profile.outlet);
  const pin = useSelector((state) => state.user.profile.pin);
  const accessList = useSelector((state) => state.user.profile.permission);
  const isTabletModeAccess = accessList
    .find((menu) => menu.name === "Pengaturan")
    ?.child?.find((submenu) => submenu.name === "Mode Tablet");
  const viewMode = localStorage.getItem("view-mode");

  const [showPassword, setShowPassword] = useState(false);
  const [showPIN, setShowPIN] = useState(false);

  const onLogOut = (e) => {
    Swal("Yakin keluar aplikasi?", {
      dangerMode: true,
      cancel: true,
      buttons: true,
      icon: "info",
    }).then((result) => {
      if (result) {
        dispatch(logout(history));
      }
    });
  };

  const switchToTablet = () => {
    history.push("/tablet-mode");
  };

  const switchToDesktop = () => {
    localStorage.setItem("view-mode", "desktop");
    history.push("/dashboard");
  };

  const editPass = () => {
    setShowPassword(true);
  };
  const closePass = () => {
    setShowPassword(false);
  };
  const handleClosePass = (data) => {
    setShowPassword(data);
  };

  const editPIN = () => {
    setShowPIN(true);
  };
  const closePIN = () => {
    setShowPIN(false);
  };
  const handleClosePIN = (data) => {
    setShowPIN(data);
  };

  return (
    <>
      <CDropdown variant="nav-item" alignment="end">
        <CDropdownToggle
          placement="bottom-end"
          className="py-0 text-light profile-wrapper"
          caret={false}
        >
          <CFormLabel
            className={`mr-4 ${viewMode === "tablet" && "text-body"}`}
          >
            {profile} - {role} - {outlet}
          </CFormLabel>
          <>{ava.split("/").pop() ? <CAvatar src={ava} size="md" /> : <></>}</>
        </CDropdownToggle>
        <CDropdownMenu className="pt-0 nav-top-header-account">
          {isTabletModeAccess &&
            (viewMode === "tablet" ? (
              <CDropdownItem onClick={switchToDesktop}>
                <CIcon icon={cilScreenDesktop} className="me-2" />
                &nbsp; Mode Desktop
              </CDropdownItem>
            ) : (
              <CDropdownItem onClick={switchToTablet}>
                <CIcon icon={cilTablet} className="me-2" />
                &nbsp; Mode Tablet
              </CDropdownItem>
            ))}
          <CDropdownItem onClick={editPass}>
            <CIcon icon={cilLockLocked} className="me-2" />
            &nbsp; Change Password
          </CDropdownItem>
          {pin && (
            <CDropdownItem onClick={editPIN}>
              <CIcon icon={cilDialpad} className="me-2" />
              &nbsp; Change PIN
            </CDropdownItem>
          )}
          <CDropdownItem onClick={() => onLogOut()}>
            <CIcon icon={cilAccountLogout} className="me-2" />
            &nbsp; Logout
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>

      <CModal
        visible={showPassword}
        onClose={closePass}
        alignment="center"
        size="lg"
        keyboard={false}
      >
        <CModalHeader
          component="h5"
          className="header-wrapper-manajemen-role"
          onClose={closePass}
        >
          {" "}
          Edit Password
        </CModalHeader>
        <CModalBody>
          <ChangePassword nip={nip} handleClose={handleClosePass} />
        </CModalBody>
      </CModal>

      <CModal
        visible={showPIN}
        onClose={closePIN}
        alignment="center"
        size="sm"
        keyboard={false}
      >
        <CModalHeader
          component="h5"
          className="header-wrapper-manajemen-role"
          onClose={closePIN}
        >
          {" "}
          Edit PIN
        </CModalHeader>
        <CModalBody>
          <ChangePIN nip={nip} handleClose={handleClosePIN} />
        </CModalBody>
      </CModal>
    </>
  );
};

export default AppHeaderDropdown;
