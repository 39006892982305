import axios from "axios";

const HistoriAPI = {
  getAllNotifByRole: async ({
    token,
    employee,
    currentPage,
    projectPerPage,
  }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/notification/notifForSpv?employeeId=${employee}&currentPage=${currentPage}&pageSize=${projectPerPage}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  getAllNotif: async ({ token, employee, currentPage, projectPerPage }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/notification?employeeId=${employee}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  getReservasiMejaByNo: async ({ token, reservationNo }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/reservation/${reservationNo}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  getReservasiPaketByNo: async ({ token, reservationNo }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/reservationpackage/${reservationNo}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  getDetailNotif: async ({ token, notifId }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/notification/detailNotif/${notifId}`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  readNotif: async ({ token, notifId }) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/notification/readNotif/${notifId}`,
      {},
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  checkNotif: async ({ token, notifId }) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/notification/checkNotif/${notifId}`,
      {},
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  changeSPV: async ({ token, notifId, payload }) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/notification/changeSPV/${notifId}`,
      payload,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },

  getNotifTable: async ({ token }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/notification/notifTable`,
      {
        headers: {
          access_token: `${token}`,
        },
      }
    );

    return data;
  },
};

export default HistoriAPI;
