/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  CCol,
  CRow,
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
} from "@coreui/react-pro";
import { useSelector } from "react-redux";
import Swal from "sweetalert";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { encode } from "base-64";
import HistoriAPI from "src/views/kalasanPages/histori/APIHistori";
import { useMutation, useQuery } from "react-query";
import ResponseError from "src/views/kalasanPages/penjualan/waitress/waitressComponents/responseError";
import Select from "react-select";
import API from "src/utils/API";

const ListSPV = ({
  visible,
  closeModal,
  closeModalDetail,
  dataDetail,
  refetch,
}) => {
  const token = useSelector((state) => state.user.profile.token);
  const dispatch = useDispatch();
  const history = useHistory();
  const [supervisorOptions, setSupervisorOptions] = useState([]);
  const [selectedSupervisor, setSelectedSupervisor] = useState("");

  const { isLoading: isLoadingGetSupervisor, refetch: refetchSupervisor } =
    useQuery(
      ["get-supervisor"],
      () =>
        API.getSupervisor({
          token,
        }),
      {
        enabled: !!visible,
        onSuccess: (res) => {
          if (res.data) {
            const options = res.data.map((item) => ({
              label: item.name,
              value: item.employee_id,
            }));

            setSupervisorOptions(options);
          }
        },
        onError: (err) => {
          ResponseError(err, dispatch, history);
        },
      }
    );

  const { isLoading: isLoadingChangeSPV, mutate: updateSPV } = useMutation(
    HistoriAPI.changeSPV,
    {
      onSuccess: () => {
        Swal("Supervisor berhasil diubah", "", "success");
        refetch();
        closeModal();
        closeModalDetail();
      },
      onError: (err) => {
        ResponseError(err, dispatch, history);
      },
    }
  );

  const selectSupervisor = (e) => {
    setSelectedSupervisor(e.value);
  };

  const handleSubmit = () => {
    Swal("Yakin ingin mengganti supervisor?", {
      dangerMode: true,
      cancel: true,
      buttons: true,
      icon: "info",
    }).then((result) => {
      if (result) {
        updateSPV({
          token,
          notifId: encode(dataDetail.notification_detail_id),
          payload: {
            employee_id: selectedSupervisor,
            type: dataDetail.type,
            reservation_id: dataDetail.reference_id,
            notification_id: dataDetail.notification_id,
            role_id: dataDetail.role_id,
          },
        });
      }
    });
  };
  return (
    <CModal visible={visible} onClose={closeModal} alignment="top" size="md">
      <CModalHeader
        component="h5"
        className="header-wrapper-manajemen-role"
        onClose={closeModal}
      >
        {" "}
        Pilih Supervisor
      </CModalHeader>
      <CModalBody>
        <CRow>
          <Select
            options={supervisorOptions}
            onChange={(e) => selectSupervisor(e)}
          />
        </CRow>
        <CRow className="mt-1">
          <CCol className="d-flex justify-content-end">
            <CButton className="btn-add-data" onClick={handleSubmit}>
              Simpan
            </CButton>
          </CCol>
        </CRow>
      </CModalBody>
    </CModal>
  );
};

export default ListSPV;
