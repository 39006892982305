import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import { CBadge } from "@coreui/react-pro";

export const AppSidebarNav = ({ items }) => {
  const location = useLocation();
  const navLink = (name, icon, badge) => {
    return (
      <>
        {icon && icon}
        {name && name}
        {badge && (
          <CBadge color={badge.color} className="ms-auto">
            {badge.text}
          </CBadge>
        )}
      </>
    );
  };

  const navItem = (item, index) => {
    const { component, name, badge, icon, ...rest } = item;
    const Component = component;

    return (
      <>
        {rest.to === "/sales/order" ||
        rest.to === "/sales/cashier" ||
        rest.to === "/tablet-mode" ? (
          // IF WAITRESS
          <a
            target="_blank"
            href={rest.to}
            style={{ textDecoration: "none", cursor: "default" }}
            rel="noreferrer"
          >
            <Component
              // {...(rest.to &&
              //   !rest.items && {
              //     component: NavLink,
              //     activeClassName: "active",
              //   })}
              key={index}
              {...rest}
            >
              {navLink(name, icon, badge)}
            </Component>
          </a>
        ) : (
          <a
            href={rest.to}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Component
              {...(rest.to &&
                !rest.items && {
                  // component: NavLink,
                  activeClassName: "active",
                })}
              key={index}
              {...rest}
            >
              {navLink(name, icon, badge)}
            </Component>
          </a>
        )}
      </>
    );
  };

  const navGroup = (item, index) => {
    const { component, name, icon, to, ...rest } = item;
    const Component = component;
    return (
      <Component
        idx={String(index)}
        key={index}
        toggler={navLink(name, icon)}
        visible={location.pathname.startsWith(to)}
        {...rest}
      >
        {item.items?.map((item, index) =>
          item.items ? navGroup(item, index) : navItem(item, index)
        )}
      </Component>
    );
  };

  return (
    <React.Fragment>
      {items &&
        items.map((item, index) =>
          item.items ? navGroup(item, index) : navItem(item, index)
        )}
    </React.Fragment>
  );
};

AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};
